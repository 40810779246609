<template>
  <div v-if="isProductValid(product)" class="grid grid-cols-8">
    <ProductCard
      v-if="product"
      :id="product.id"
      :key="`product-disruptor-${product.id}`"
      :product="product"
      :badge-label="badgeLabel"
      :class="{
        'col-span-full': width === '1' || width === undefined,
        'col-span-full md:col-span-4 md:col-start-3':
          (width === '2' && listingColumns === 3) || listingColumns === 1,
        'col-span-full md:col-span-8': width === '2' && listingColumns === 2,
      }"
      :colors="getAttributeValueTuples(product.attributes, 'colorDetail')"
      :name="getFirstAttributeLabel(product.attributes, 'name')"
      :link="fimLocalePath(getProductDetailRoute(product))"
      :image="getFirstModelImage(product.images)"
      :hover-images="getModelImages(product.images).reverse()"
      :show-add-to-cart="false"
      :loading="fetching"
      :price="getLowestPrice(product)"
      :variants="product.variants"
      :title="getFirstAttributeLabel(product.attributes, 'brand')"
      placement="PDP-carousel"
      @intersect:product="$emit('intersect:product', product)"
      @click:select-item="$emit('click:product', product)"
    />
  </div>
</template>

<script setup lang="ts">
import { getAttributeValueTuples, type Product } from '@scayle/storefront-nuxt'
import useListingUiState from '~/composables/ui/useListingUiState'

const fimLocalePath = useFimLocalePath()

defineEmits<{
  (e: 'click:product' | 'intersect:product', value: Product): void
}>()

const props = defineProps({
  width: {
    type: String as PropType<string>,
    default: undefined,
  },
  fetching: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  product: {
    type: Object as PropType<Product>,
    default: () => ({}),
  },
})

const $i18n = useI18n()
const { listingColumns } = useListingUiState()
const { getProductDetailRoute } = useRouteHelpers()

const badgeLabel = computed(() => {
  const label = getBadgeLabel({
    isNew: props.product.isNew,
    isSoldOut: props.product.isSoldOut,
  })
  return label ? $i18n.t(`badge_labels.${label}`) : undefined
})
</script>
